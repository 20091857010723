import React from 'react'
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { createClient } from "@supabase/supabase-js";

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  // const supabaseUrl = process.env.SUPABASE_URL;
  // const supabaseKey = process.env.SUPABASE_KEY;
  const supabase = createClient(
    process.env.SUPABASE_URL,
    process.env.SUPABASE_KEY,
  );

  let mySubscription = null;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setUserEmail] = useState("");
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState("");
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [routeHash, setRouteHash] = useState("");
  const [isOnBottom, setIsOnBottom] = useState(false);
  const [newIncomingMessageTrigger, setNewIncomingMessageTrigger] =
    useState(null);
  const [unviewedMessageCount, setUnviewedMessageCount] = useState(0);
  const [countryCode, setCountryCode] = useState("");

  const getLocation = async () => {
    try {
      const res = await fetch("https://api.db-ip.com/v2/free/self");
      const { countryCode, error } = await res.json();
      if (error) throw new Error(error);

      setCountryCode(countryCode);
      localStorage.setItem("countryCode", countryCode);
    } catch (error) {
      console.error(
        `error getting location from api.db-ip.com:`,
        error.message
      );
    }
  };

  const randomUsername = () => {
    return `@user${Date.now().toString().substr(-4)}`;
  };
  const initializeUser = async () => {
    const data = await supabase.auth.getSession()
    // const user = supabase.auth.user();
    if (!data) { return}
    const user_session = data.data.session;
    const user = user_session?.user;
    // console.log('initializeUser', user);
    let username;
    let email;
    if (user?.user_metadata?.user_name) {
      username = user?.user_metadata?.user_name;
      email = user?.user_metadata?.email;
      setIsAuthenticated(true);
    } else {
      username = localStorage.getItem("username") || randomUsername();
      setIsAuthenticated(false);
    }
    setUsername(username);
    setUserEmail(email);
    localStorage.setItem("username", username);
    localStorage.setItem("email", email);
  };

  useEffect(() => {
    initializeUser();
    getMessagesAndSubscribe();

    // const storedUser = localStorage.getItem("username");
    // if (storedUser) setUsername(storedUser);
    // else setUsername(`@user${Date.now().toString().substr(-4)}`);

    const storedCountryCode = localStorage.getItem("countryCode");
    if (storedCountryCode && storedCountryCode !== "undefined")
      setCountryCode(storedCountryCode);
    else getLocation();

    supabase.auth.onAuthStateChange((event, session) => {
      // console.log("onAuthStateChange", { event, session });
      if (event === "SIGNED_IN") initializeUser();
    });
    // const { hash, pathname } = window.location;
    // if (hash && pathname === "/") {
    //   console.log("hash", hash);
    //   setRouteHash(hash);
    // }

    return () => {
      supabase.removeAllChannels();
      console.log("Remove supabase subscription by useEffect unmount");
    };
  }, []);

  useEffect(() => {
    if (newIncomingMessageTrigger?.username === username) scrollToBottom();
    else setUnviewedMessageCount((prevCount) => prevCount + 1);
  }, [newIncomingMessageTrigger]);

  const handleNewMessage = (payload) => {
    // console.log('payload', payload);
    setMessages((prevMessages) => [payload.new, ...prevMessages]);
    //* needed to trigger react state because I need access to the username state
    setNewIncomingMessageTrigger(payload.new);
  };

  const getInitialMessages = async () => {
    if (!messages.length) {
      const { data, error } = await supabase
        .from("messages")
        .select()
        .range(0, 49)
        .order("id", { ascending: false });
      // console.log(`data`, data);
      setLoadingInitial(false);
      if (error) {
        setError(error.message);
        supabase.removeChannel(mySubscription);
        mySubscription = null;
        return;
      }
      setMessages(data);
      scrollToBottom();
    }
  };

  const getMessagesAndSubscribe = async () => {
    setError("");
    if (!mySubscription) {
      getInitialMessages();
      mySubscription = supabase
        .channel("messages")
        .on("postgres_changes", { event: 'INSERT', schema: 'public', table: 'messages' }, (payload) => {
          handleNewMessage(payload);
        }).subscribe()
    }
  };

  const scrollRef = useRef();
  const onScroll = async ({ target }) => {
    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
      setUnviewedMessageCount(0);
      setIsOnBottom(true);
    } else {
      setIsOnBottom(false);
    }

    //* Load more messages when reaching top
    if (target.scrollTop === 0) {
      // console.log("messages.length :>> ", messages.length);
      const { data, error } = await supabase
        .from("messages")
        .select()
        .range(messages.length, messages.length + 49)
        .order("id", { ascending: false });
      if (error) {
        setError(error.message);
        return;
      }
      target.scrollTop = 1;
      setMessages((prevMessages) => [...prevMessages, ...data]);
    }
  };

  const scrollToBottom = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  async function signInWithGithub() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'github',
      options: {
        redirectTo: process.env.APP_HOST // 'http://localhost:9000',
        // redirectTo: 'https://7759-2401-4900-1c0a-6200-7973-b485-ae8c-cb1a.ngrok-free.app/settings' // 'http://localhost:9000',
      },
    })
  }

  async function signOut() {
    const { error } = await supabase.auth.signOut()
  }
  // console.log('supabase', supabase);
  return (
    <AppContext.Provider
      value={{
        supabase,
        auth: supabase.auth,
        messages,
        loadingInitial,
        error,
        getMessagesAndSubscribe,
        username,
        setUsername,
        email,
        setUserEmail,
        randomUsername,
        routeHash,
        scrollRef,
        onScroll,
        scrollToBottom,
        isOnBottom,
        country: countryCode,
        unviewedMessageCount,
        signInWithGithub,
        signOut,
        isAuthenticated,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider, useAppContext };
