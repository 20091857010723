exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-button-toggle-index-tsx": () => import("./../../../src/pages/apps/button-toggle/index.tsx" /* webpackChunkName: "component---src-pages-apps-button-toggle-index-tsx" */),
  "component---src-pages-apps-counter-index-tsx": () => import("./../../../src/pages/apps/counter/index.tsx" /* webpackChunkName: "component---src-pages-apps-counter-index-tsx" */),
  "component---src-pages-apps-directory-index-tsx": () => import("./../../../src/pages/apps/directory/index.tsx" /* webpackChunkName: "component---src-pages-apps-directory-index-tsx" */),
  "component---src-pages-blog-index-backup-tsx": () => import("./../../../src/pages/blog/index-backup.tsx" /* webpackChunkName: "component---src-pages-blog-index-backup-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-blog-tags-tsx": () => import("./../../../src/pages/blog/tags.tsx" /* webpackChunkName: "component---src-pages-blog-tags-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pomodoro-logs-tsx": () => import("./../../../src/pages/pomodoro-logs.tsx" /* webpackChunkName: "component---src-pages-pomodoro-logs-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-templates-social-media-bento-me-index-tsx": () => import("./../../../src/pages/templates/social-media/bento.me/index.tsx" /* webpackChunkName: "component---src-pages-templates-social-media-bento-me-index-tsx" */),
  "component---src-pages-templates-social-media-linktr-ee-index-tsx": () => import("./../../../src/pages/templates/social-media/linktr.ee/index.tsx" /* webpackChunkName: "component---src-pages-templates-social-media-linktr-ee-index-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-post-solution-tsx": () => import("./../../../src/templates/post-solution.tsx" /* webpackChunkName: "component---src-templates-post-solution-tsx" */),
  "component---src-templates-potd-question-tsx": () => import("./../../../src/templates/potd-question.tsx" /* webpackChunkName: "component---src-templates-potd-question-tsx" */),
  "component---src-templates-potd-solution-tsx": () => import("./../../../src/templates/potd-solution.tsx" /* webpackChunkName: "component---src-templates-potd-solution-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

